<template>
  <v-card>
    <Header :title="$t('profile.friends.title')" >
    </Header>
<!-- <v-btn @click="requestFriend">Request</v-btn> -->
    <v-card-text v-if="befriendUrl">
      <h3>{{ $t('profile.friends.invite-friend') }}</h3>
      <p>{{ $t('profile.friends.invite-friend-msg') }}</p>
      <v-dialog max-width="400px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            <v-icon class="me-2">fadl fa fa-qrcode</v-icon> {{ $t('shared.show-qr-code') }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title>{{ $t('shared.qr-code') }}</v-card-title>
          <v-card-text>{{ $t('profile.friends.share-msg') }}</v-card-text>
          <QrCode :data="befriendUrl" />
        </v-card>
      </v-dialog>
      <v-btn color="primary" outlined class="ms-4" @click="$helpers.copyToClipboard(befriendUrl, $toast)">{{ $t('shared.copy-link') }}</v-btn>
    </v-card-text>

    <div v-if="requested && requested.length">
      <v-card-subtitle class="subtitle">{{ $t('profile.friends.open-requests') }}</v-card-subtitle>
      <v-card-text>{{ $t('profile.friends.open-requests-msg') }}</v-card-text>
      <v-data-table 
        :headers="tableHeaders"
        :items="requested"
        item-key="id"
        sort-by="created"
        :items-per-page="50"
        :show-group-by="false"
      >
        <template v-slot:item.id="{ item }">
          <span>{{ item.friend.name }}</span>
        </template>
        <template v-slot:item.created="{ item }">
          {{ item.created | localDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <span v-if="item.requested_by_id == profile.id">{{ $t('profile.friends.waiting-for-response') }}</span>
          <span v-else>
            <v-btn text color="green" @click="acceptFriendRequest(item)">{{ $t('profile.friends.accept') }}</v-btn>
            <v-btn text @click="ignoreFriendRequest(item)">{{ $t('profile.friends.ignore') }}</v-btn>
          </span>
        </template>
      </v-data-table>
    </div>

    <v-card-subtitle class="subtitle">{{ $t('profile.friends.title') }}</v-card-subtitle>
    <v-card-text>{{ $t('profile.friends.message') }}</v-card-text>

    <v-data-table
      v-if="accepted"
      :headers="tableHeaders"
      :items="accepted"
      item-key="id"
      sort-by="created"
      :items-per-page="50"
      :show-group-by="false"
    >
      <template v-slot:item.id="{ item }">
        <v-btn text color="primary" @click="openFriendDialog(item)">{{ item.friend.name }}</v-btn>
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | localDate }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn outlined color="primary" @click="challengeFriend(item)"><v-icon small class="me-2">fa-trophy</v-icon> {{ $t('profile.friends.challenge') }}</v-btn>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on">
              <v-icon>fa-ellipsis-v</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="deleteFriend(item)">
              <v-list-item-icon><v-icon>fa-unlink</v-icon></v-list-item-icon>
              <v-list-item-title>{{ $t('profile.friends.unfriend') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="blockFriend(item)">
              <v-list-item-icon><v-icon>fa-ban</v-icon></v-list-item-icon>
              <v-list-item-title>{{ $t('profile.friends.block') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <div v-if="challenges && challenges.length">
      <v-card-subtitle class="subtitle">{{ $t('profile.friends.challenges') }}</v-card-subtitle>
      <v-card-text>{{ $t('profile.friends.challenges-msg') }}</v-card-text>
      <EventGrid :routeResolver="resolveViewChallengeRoute" :items="challenges" :minimal="true" class="mx-4 mb-4"></EventGrid>
    </div>

    <div v-if="blocked && blocked.length">
      <v-card-subtitle class="subtitle">{{ $t('profile.friends.blocked-users') }}</v-card-subtitle>
      <v-card-text>{{ $t('profile.friends.blocked-users-msg') }}</v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="blocked"
        item-key="id"
        sort-by="created"
        :items-per-page="50"
        :show-group-by="false"
      >
        <template v-slot:item.id="{ item }">
          <span>{{ item.friend.name }}</span>
        </template>
        <template v-slot:item.created="{ item }">
          {{ item.created | localDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn text small @click="deleteFriend(item)"><v-icon small class="me-2">fa-unlink</v-icon> {{ $t('shared.delete') }}</v-btn>
        </template>
      </v-data-table>
    </div>
    <FriendDialog ref="friendDialog"/>
  </v-card>
</template>



<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import QrCode from '@/components/generic/QrCode.vue'
import EventGrid from "@/components/EventGrid.vue";
import FriendDialog from "@/components/FriendDialog.vue";
import ShareButton from '@/components/ShareButton.vue';
import Header from '../_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Friends",
  components: {
    Header,
    QrCode,
    ShareButton,
    EventGrid,
    FriendDialog,
  },
  props: {
  },
  data() {
    return {
      befriendUrl: null,
      challenges: null,
      requested: null,
      accepted: null,
      profile: null,
      blocked: null,
      tableHeaders: [ 
        { value: 'id', text: this.$t('profile.friends.cols.name') }, 
        { value: 'created', text: this.$t('profile.friends.cols.created') }, 
        { value: 'actions', text: '' }, 
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        console.log(this.user);
        this.profile = (await profileService.get()).data;
        this.befriendUrl = (await profileService.getFriendInviteLink()).data.msg;
        let allFriends = (await profileService.getFriends()).data.data;
        this.requested = allFriends.filter(x => x.status === 'REQUESTED');
        this.accepted = allFriends.filter(x => x.status === 'ACCEPTED') || [];
        this.blocked = allFriends.filter(x => x.status === 'BLOCKED');

        this.challenges = (await profileService.getFriendChallenges()).data.data;
      }
    },
    /*async requestFriend() {
      if (confirm('Are you sure you want to request?')){
        const response = (await profileService.requestFriend('TODO')).data;
        this.$helpers.toastResponse(this, response, 'The request has been sent successfully.');
        await this.getProfile();
      }
    },*/
    resolveViewChallengeRoute(eventId) {
      return {name: 'profileFriendsChallengeView', params: {eventId:eventId }};
    },
    openFriendDialog(item) {
      this.$refs.friendDialog.open(item);
    },
    async acceptFriendRequest(item) {
      if (confirm(this.$t('profile.friends.accept-request-prompt'))){
        const response = (await profileService.acceptFriendRequest(item.friend.id)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.accept-request-confirmation'));
        await this.getProfile();
      }
    },
    async ignoreFriendRequest(item) {
      if (confirm(this.$t('profile.friends.ignore-request-prompt'))){
        const response = (await profileService.ignoreFriendRequest(item.friend.id)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.ignore-request-confirmation'));
        await this.getProfile();
      }
    },
    async deleteFriend(item) {
      if (confirm(this.$t('profile.friends.delete-user-prompt'))){
        const response = (await profileService.deleteFriend(item.friend.id)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.delete-user-confirmation'));
        await this.getProfile();
      }
    },
    async blockFriend(item) {
      if (confirm(this.$t('profile.friends.block-user-prompt'))){
        const response = (await profileService.blockFriend(item.friend.id)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.block-user-confirmation'));
        await this.getProfile();
      }
    },
    async challengeFriend(item) {
      if (confirm(this.$t('profile.friends.challenge-user-prompt'))){
        const request = {
          timezone: DateTime.now().zoneName,
        };
        const response = (await profileService.challengeFriend(item.friend.id, request)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.challenge-user-confirmation'));
        if (response.status == 'OK') {
          this.$router.push({ name: 'profileFriendsChallengeConfig', params: { eventId: response.id}, query: this.$route.query });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),

  },

};
</script>


