<template>
  <span v-if="link" :title="link.race_name">
    <v-icon v-if="withIcon" :title="link.scoring" class="mr-2">{{$helpers.getScoringIcon(link.scoring)}} </v-icon>
    <span v-if="link.scoring == 'RESULT'" class="">{{ -1*link.val | duration }}</span>
    <span v-else-if="link.scoring == 'DISTANCE'" class="">{{ link.val | distance(profile.unit) }}</span>
    <span v-else-if="link.scoring == 'TIME'" class="">{{ link.val | duration }}</span>
    <span v-else-if="link.scoring == 'ELEVATION'" class="">{{ link.val | distance(profile.unit) }}</span>
    <span v-else-if="link.scoring == 'STEPS'" class="">{{ link.val | steps }} {{ $t('profile.activities.steps') | lowercase}} </span>
    <span v-else-if="link.scoring == 'CUSTOM'" class="">
      {{ link.val }} {{ link.custom || $t('profile.activities.points') | lowercase }} 
      <TooltipDialog v-if="link.scoring_desc" title="Scoring for this race" :text="link.scoring_desc" />
    </span>    
    <span v-else-if="link.val === 0" class="">✔️</span>
    <span v-else class="">{{ link.val }}</span>
  </span>
</template>

<script>

export default {
  name: "ActivityRaceLink",
  components: {
  },
  props: {
    link: Object,
    profile: Object,
    withIcon: Boolean,
  },
  data() {
    return {
    };
  },
  async mounted() {
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style lang="scss">
</style>

