<template>
   <v-dialog v-if="friend" v-model="showDialog">
    <v-card class="">
      <v-card-title class="subtitle d-block align-center grey darken-2 white--text">
        <v-avatar v-if="friend.img" size="100" class="mb-4"><img :src="friend.img"/></v-avatar>
        <div>{{ friend.name }}</div>
      </v-card-title>
      <v-card-text v-if="!details">
        <i><v-icon>fa fa-circle-notch fa-spin</v-icon> {{ $t('shared.loading') }}</i>
      </v-card-text>
      <div v-else>
        <v-tabs
          v-model="tab"
          background-color="grey darken-2"
          centered
          dark
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-if="details.steps_by_date" href="#tab-0">
            Steps
            <v-icon>fa-shoe-prints</v-icon>
          </v-tab>

          <v-tab href="#tab-1">
            Activities
            <v-icon>fa-stopwatch</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            Messages
            <v-icon>fa-comments</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-if="details.steps_by_date" value="tab-0">
            <v-card-subtitle>{{ $t('profile.friends.details.steps-title') }}</v-card-subtitle>
            <RaceHistogramByDate :data="details.steps_by_date" :label="$t('results.details.score-by-day-steps')" :defaultColor="$vuetify.theme.defaults.light.primary"  :accentColor="$helpers.getColorVariant($vuetify.theme.defaults.light.primary, 100)" />
          </v-tab-item>

          <v-tab-item value="tab-1">
            <v-card-subtitle>{{ $t('profile.friends.details.activities-title') }}</v-card-subtitle>
            <v-card-text v-if="details.activities && !details.activities.length">{{ $t('profile.friends.details.activities-no-data', {friend: friend.name}) }}</v-card-text>
            <ActivityGrid v-if="details.activities && details.activities.length" ref="activityGrid" :data="this.details.activities" :limit="25" :autoLoad="false" class="mb-4" />
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card-subtitle>{{ $t('profile.friends.details.messages-title') }}</v-card-subtitle>
            <v-card-text>{{ $t('profile.friends.details.messages-msg', {friend: friend.name}) }}</v-card-text>
            <FeedList :feedItems="details.messages" compact :emptyLabel="$t('profile.friends.details.messages-no-data', {friend: friend.name})" />
            <PostCommentField @submit="postMessage" class="mx-4" />
          </v-tab-item>
        </v-tabs-items>
      </div> 
      <v-card-actions>
        <v-btn text @click="showDialog=false">Close</v-btn>
      </v-card-actions>
    </v-card>
   </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import ActivityGrid from "@/components/ActivityGrid";
import FeedList from '@/components/FeedList.vue'
import PostCommentField from '@/components/PostCommentField.vue'
import RaceHistogramByDate from '@/components/RaceHistogramByDate.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "FriendDialog",
  props: {
  },
  components: {
    ActivityGrid,
    PostCommentField,
    FeedList,
    RaceHistogramByDate,
  },
  data() {
    return {
      tenant: tenant,
      friend: null,
      details: null,
      showDialog: false,
      tab: 0,
    };
  },
  mounted() {
  },
  methods: {
    async open(friendLink) {
      this.friend = friendLink.friend;
      this.showDialog = true;
      await this.loadData();
    },
    async loadData() {
      this.details = (await profileService.getFriendDetails(this.friend.id)).data;
      await this.$nextTick();
      //this.$refs.activityGrid.loadActivities(this.details.activities);
    },
    async postMessage(message) {
      let result = (await profileService.messageFriend(this.friend.id, message)).data;
      this.$helpers.toastResponse(this, result, 'Message sent!');
      if (result.status == 'OK') {
        await this.loadData();
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped>
</style>