<template>
  <div>
    <v-skeleton-loader v-if="!user || !activities" type="table"></v-skeleton-loader>
    <v-simple-table fixed-header v-if="activities && activities.length > 0" >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{$t('profile.activities.sport')}}</th>
            <th class="text-left">{{$t('profile.activities.source')}}</th>
            <th class="text-left">{{$t('profile.activities.date')}}</th>
            <th class="text-left">{{$t('profile.activities.time')}}</th>
            <th class="text-left">{{$t('profile.activities.distance')}}</th>
            <th v-if="profile" class="text-left">{{$t('profile.activities.results')}}</th>
            <th v-if="profile" class="text-left px-0">&nbsp;</th>
            <th v-if="profile" class="text-left">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <router-link v-for="(item, idx) in activities" :key="'act'+idx" :to="{name: 'activity', params: {id: item.id}}" v-slot="{ href, navigate }">
            <tr :href="href" @click="navigate" :class="`type--${item.type}`">
              <td class="pe-0" style="width:30px;"><v-icon :title="item.type">{{$helpers.getActivityIcon(item.type)}}</v-icon></td>
              <td class="pe-0" style="width:30px;"><v-icon :title="item.prov">{{$helpers.getProviderIcon(item.prov)}}</v-icon></td>
              <td v-if="adminMode">{{ item.start | localDate('L LTS Z') }}</td>
              <td v-else-if="item.type == 'DAILY_SUMMARY'">{{ item.start | localDate('L') }}</td>
              <td v-else>{{ item.start | localDate('L LTS') }}</td>
              <td>{{ item.time_s | duration }} </td>
              <td v-if="item.type == 'DAILY_SUMMARY' && item.steps">{{ $t('results.formatting.steps', { value: $options.filters.int(item.steps)}) }} </td>
              <td v-else-if="item.type == 'STAIR_CLIMBING'">{{ item.custom }} stairs </td>
              <td v-else>{{ item.dist | distance(profile && profile.unit, /*ignoreUnit:*/ false, item.type) }} </td>
              <td v-if="profile && item.race_links && item.race_links.length > 0" :set="link=item.race_links.find(l => l.val > 0)">
                <ActivityRaceLink :link="link" :profile="profile" />
                <span v-if="item.race_links.length > 1" class="text-muted">+ {{ item.race_links.length-1 }}</span>
              </td>
              <!-- <td v-if="item.race_links && item.race_links.length > 0">
                <span v-for="(link, idx) in item.race_links" :key="idx">
                  <span v-if="idx != 0">•</span>
                  <ActivityRaceLink :link="link" :profile="profile" />
                </span>
              </td> -->
              <td v-else-if="profile">
                <span class="text-muted">{{$t('shared.n-a')}}</span>
              </td>
              <td v-if="profile">
                <v-btn v-if="false && !adminMode" text small color="accent" :to="{name:'profileUpload', query: {activityId: item.id}}" :title="$t('profile.upload-photo.upload-photo')"><v-icon small class="fa fa-camera-alt" /></v-btn>
                <v-btn v-if="!adminMode" text small color="primary" :to="{name: 'activity', params: {id: item.id}}">{{$t('shared.view')}}</v-btn>
                <v-btn v-if="adminMode" icon small color="red" @click.prevent="$emit('delete', item)"><v-icon small color="red" class="fa fa-trash" /></v-btn>
              </td>
            </tr>
          </router-link>
        </tbody>
      </template>
    </v-simple-table>
    <v-divider  />
    <div v-if="!compact && meta && meta.has_more" class="full-width-separator text-center mt-6">
      <v-btn class="" rounded outlined @click="loadNextPage">{{$t('shared.load-more-data')}}</v-btn>
    </div>
    <v-alert type="info" tile v-if="activities && activities.length == 0">
      {{$t('profile.activities.no-activities-yet')}}
    </v-alert>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import activityService from "@/services/activityService";
import ActivityRaceLink from './ActivityRaceLink.vue';

export default {
  name: "ActivityGrid",
  components: {
    ActivityRaceLink,
  },
  props: {
    limit: Number,
    compact: Boolean,
    adminMode: Boolean,
    profile: Object,
    autoLoad: {
      type: Boolean,
      default: true,
    },
    data: Array,
  },
  data() {
    return {
      activities: null,
      meta: null,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
    if (this.data) {
      this.loadActivities(this.data);
    }
  },
  methods: {
    async loadActivities(activities) {
      this.activities = activities;
      this.meta = {};
      console.log('Loading activities', activities);
    },
    async loadResponse(response) {
      this.activities = response.data;
      this.meta = response.meta;
    },
    async loadData() {
      if (this.user && this.autoLoad) {
        if (this.adminMode) {
          activityService.impersonationProfileId = this.profile.id;
        }
        const response = (await activityService.all(this.limit)).data;
        this.meta = response.meta;
        this.activities = response.data;
      }
    },
    async loadNextPage() {
        if (this.adminMode) {
          activityService.impersonationProfileId = this.profile.id;
        }
        const response = (await activityService.allPaged(this.limit, this.meta.continuation_token)).data;
        this.meta = response.meta;
        this.activities = this.activities.concat(response.data);
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>

<style lang="scss">
  .full-width-separator {
    border-top: solid 1px rgba(0, 0, 0, 0.27);;
    .v-btn {
      margin-top: -24px !important;
      background-color: white;
    }
  }
  .type--DAILY_SUMMARY td, .type--DAILY_SUMMARY .v-icon {
    color: #aaa !important;
  }
</style>

